<template>
  <div v-if="!!durations" ref="mediaCarousel" class="oap-media-carousel">
    <ul class="oap-media-carousel__inner">
      <slot></slot>
    </ul>
    <OapVideoCarouselControls
      v-if="!!durations.length && !isSingleSlide"
      :carousel-length="slidesLength"
      :durations-list="durations"
      :controls-type="controlsType"
      :current-video-has-autoplay="currentVideoHasAutoplay"
      :has-controls-animation="hasControlsAnimation"
      :analytics="analytics"
      :video-title="getVideoTitle"
      @toggle-slide="setSlide"
      @pause-slide="pauseSlide"
      @show-next-slide="setSlidingDirection"
      @current-time="onCurrentTimeChange"
    />
  </div>
</template>

<script>
import OapVideoCarouselControls from './OapMediaCarouselControls';
import eventBus from '@loreal/eventbus-js';
import { once, intersectionViewportObserver, AnalyticsHandler } from '@Foundation';

const SLIDE = {
  display: {
    show: '--shown',
  },

  animation: {
    appearFromRight: '--from-right',
    appearFromLeft: '--from-left',
    disappearToRight: '--to-right',
    disappearToLeft: '--to-left',
  },

  hidingTimeoutDuration: 350,
};

export default {
  name: 'OapMediaCarousel',
  components: {
    OapVideoCarouselControls,
  },
  props: {
    duration: { type: Number, default: 5000 },
    controlsType: { type: String, default: 'dots' },
    hasControlsAnimation: { type: Boolean, default: true },
    analytics: { type: Object, default: () => {} },
  },
  data() {
    return {
      slideIndex: 0,
      durations: [],
      videoCounter: 0,
      callbackVideoCounter: 0,
      videosData: {},
      isPlaying: true,
      shouldShowNextSlide: true,
      videosToLazyload: [],
      currentAnimationTime: 0,
      currentVideoHasAutoplay: false,
    };
  },
  computed: {
    slidesLength() {
      return this.$slots.default().filter((node) => node.type === 'li').length;
    },

    getSlides() {
      return [...this.$refs.mediaCarousel.querySelectorAll('.oap-media-carousel__slide')];
    },

    getVideoTitle() {
      return this.getSlides[this.slideIndex].querySelector('video')?.getAttribute('title');
    },

    shouldVideoAutoplay() {
      const video = this.getSlides[this.slideIndex].querySelector('video');

      if (!video) return;

      return JSON.parse(video.dataset.autoplay);
    },

    isSingleSlide() {
      return this.slidesLength === 1;
    },
  },
  watch: {
    slideIndex(newIndex, oldIndex) {
      const activeVideo = this.videosToLazyload.filter((video) => video.slideIndex === newIndex)[0];
      if (activeVideo && !activeVideo.video.hasAttribute('src')) {
        activeVideo.video.setAttribute('src', activeVideo.video.dataset.src);
      }

      this.getSlides.forEach((slide, index) => {
        const video = slide.querySelector('video');
        if (video && index === newIndex) {
          video.currentTime = 0;

          if (video.src) {
            if (!JSON.parse(video.dataset.autoplay)) {
              video.pause();
              this.currentVideoHasAutoplay = false;
            } else {
              video.play();
              this.currentVideoHasAutoplay = true;
            }
          }
        }
        slide.classList.remove(
          SLIDE.animation.appearFromRight,
          SLIDE.animation.appearFromLeft,
          SLIDE.animation.disappearToRight,
          SLIDE.animation.disappearToLeft
        );
      });

      this.onChangeSlide(newIndex, oldIndex);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.showSlides();
      this.getVideosDuration();
    });

    if (this.isSingleSlide) {
      eventBus.on('media-carousel::toggle-playing', () => {
        this.isPlaying = !this.isPlaying;
        this.pauseSlide(this.isPlaying);
      });
    }

    intersectionViewportObserver(this.$el, {
      delay: 300,
      rootMargin: '0px',
      shouldUnobserve: false,
      conditionCheckCallback: this.togglePlaying,
    });

    intersectionViewportObserver(this.$el, {
      shouldUnobserve: true,
      callbackBeforeResolve: this.onloadDataLayer,
    });
  },
  methods: {
    togglePlaying(element) {
      const eventKey = element.isIntersecting ? 'play' : 'pause';
      this.isPlaying = element.isIntersecting;
      this.pauseSlide(this.isPlaying);
      eventBus.emit(`media-carousel::${eventKey}`);
    },

    showSlideItem(index, display) {
      this.getSlides[index].classList.toggle(display);
    },

    showSlides() {
      this.getSlides.forEach((slide, index) => {
        slide.classList.remove(SLIDE.display.show);

        if (index === this.slideIndex) {
          slide.classList.add(SLIDE.display.show);
        }
      });
    },

    getVideosDuration() {
      this.getSlides.forEach((slide, index) => {
        const video = slide.querySelector('video');

        let isFirstSlideVideo = false;

        if (index === 0) {
          isFirstSlideVideo = !!video;
        }

        if (video) {
          this.videoCounter++;

          const videosToLazyloadStartPosition = isFirstSlideVideo ? 1 : 0;

          if (this.videoCounter > videosToLazyloadStartPosition) {
            this.videosToLazyload.push({
              slideIndex: index,
              video,
            });
          }

          once(video, 'loadedmetadata', () => {
            this.callbackVideoCounter++;
            this.videosData[index] = Math.floor(video.duration * 1000);

            this.updateDurationsArray();

            if (video.src) {
              if (!JSON.parse(video.dataset.autoplay)) {
                video.pause();
                this.currentVideoHasAutoplay = false;
              } else {
                this.currentVideoHasAutoplay = true;
              }
            }
          });
        } else {
          this.durations.push({ image: this.duration });
        }
      });
    },

    updateDurationsArray() {
      if (this.videoCounter === this.callbackVideoCounter) {
        this.videosToLazyload.forEach((lazyVideo) => {
          lazyVideo.video.removeAttribute('src');
          lazyVideo.video.load();
        });

        this.durations = [];
        this.getSlides.forEach((slide, index) => {
          const video = slide.querySelector('video');

          video
            ? this.durations.push({ video: this.videosData[index] })
            : this.durations.push({ image: this.duration });
        });
      }
    },

    setSlide(index) {
      this.slideIndex = index;
    },

    pauseSlide(pauseState) {
      this.getSlides.forEach((slide, index) => {
        const video = slide.querySelector('video');

        if (index === this.slideIndex && video) {
          if (pauseState) {
            if (this.controlsType !== 'dots') {
              video.currentTime = this.currentAnimationTime / 1000;
            }
            video.play();
          } else {
            video.pause();
          }
        }
      });
    },

    setSlidingDirection(isNext) {
      this.shouldShowNextSlide = isNext;
    },

    onCurrentTimeChange(currentAnimationTime) {
      this.currentAnimationTime = currentAnimationTime;
    },

    onChangeSlide(currentSlideIndex, previousSlideIndex) {
      this.getSlides[currentSlideIndex].classList.add(
        this.shouldShowNextSlide ? SLIDE.animation.appearFromRight : SLIDE.animation.appearFromLeft
      );
      this.showSlideItem(currentSlideIndex, SLIDE.display.show);
      this.getSlides[previousSlideIndex].classList.add(
        this.shouldShowNextSlide
          ? SLIDE.animation.disappearToLeft
          : SLIDE.animation.disappearToRight
      );
      setTimeout(() => {
        this.showSlideItem(previousSlideIndex, SLIDE.display.show);
      }, SLIDE.hidingTimeoutDuration);

      const video = this.getSlides[currentSlideIndex].querySelector('video');
      if (video && video.src) {
        this.currentVideoHasAutoplay = JSON.parse(video.dataset.autoplay);
      }
    },

    onloadDataLayer() {
      const isVideoFirst = !!this.getSlides[0].querySelector('video');

      if (!this.analytics || !isVideoFirst) return;

      const state = this.shouldVideoAutoplay ? 'play' : 'pause';
      let tag = this.analytics;

      tag.type = 'nonInteractiveEvent';
      tag.action = `${state}::video_header`;
      tag.video_title = this.getVideoTitle;

      try {
        AnalyticsHandler.getAnalyticsHandler().push(tag);
      } catch (e) {
        /* istanbul ignore next */
        console.warn('Could not push to dataLayer', e);
      }
    },
  },
};
</script>
