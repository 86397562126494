<template>
  <div>
    <dsf-app v-bind="config" />
  </div>
</template>

<script>
import { loadscript } from '@Foundation/directives/loadscript/loadscript';
import { SERVICE_BUTTON_CLASS, SERVICE_TRIGGER_URL_PARAM_KEY } from '../../settings';
import { mixinParseURL } from '@Foundation/mixins/parseURL/parseURL';
const MF_SCRIPT = 'modiface-script';
export default {
  name: 'OapDsfAppVtoWrapper',

  mixins: [mixinParseURL],

  props: {
    config: { type: Object, require: true },
    scriptSource: { type: String, default: 'https://dsf-cdn.loreal.io/dropper.js' },
  },

  data() {
    return {
      showDsfVtoWrapper: false,
    };
  },

  created() {
    window.addEventListener('load', () => {
      this.showDsfVtoWrapper = true;
    });
  },
  mounted() {
    window.dsfApp = document.createElement('div');
    window.dsfApp.addEventListener('onReady', (e) => {
      window.dsfApp.open(e.detail.application);
    });
    var vtoDsfCta = document.querySelectorAll(`.${SERVICE_BUTTON_CLASS}`);
    vtoDsfCta.forEach((element) => {
      element.addEventListener('click', () => {
        this.load();
       });
    });

    if (this.URLHasKey(SERVICE_TRIGGER_URL_PARAM_KEY)) {
      this.load();
    }
  },
  methods: {
    load() {
      loadscript.beforeMount(null, {
        value: {
          name: MF_SCRIPT,
          url: this.scriptSource,
        },
      });
    },
  },
};
</script>
